<template>
  <div>
    <!-- search input -->
    <div class="">
      <div class="row">
        <b-col cols="12" md="5">
          <div class="mt-2">
            <label>Language</label>
            <v-select
              v-model="languageSearch"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="blogLanguage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </div>
        </b-col>
        <b-col cols="12" md="7">
          <div class="d-flex justify-content-end">
            <b-form-group>
              <div class="">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
            <div>
              <b-button
                class="ml-2 mt-2"
                variant="primary"
                :to="{
                  name: 'blogs-addNew',
                }"
              >
                <span class="text-nowrap">Add New Blog</span>
              </b-button>
            </div>
          </div>
        </b-col>
      </div>
    </div>
    <!-- language Search -->
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <div slot="emptystate">{{ isLoading ? "Loading..." : "Sorry! Record not found" }}</div>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'title'" class="text-nowrap">
          <!-- <b-avatar :src="props.row.avatar" class="mx-1" /> -->
          <span class="text-nowrap">{{ props.row.title }}</span>
        </span>
        <span v-else-if="props.column.field === 'createdAt'" class="text-nowrap">
          <!-- <b-avatar :src="props.row.avatar" class="mx-1" /> -->
          <span class="text-nowrap">{{ formatDate(props.row.createdAt) }}</span>
        </span>

        <!-- Column: Status -->
        <!-- <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span> -->

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :to="{
                  name: 'blog-edit',
                  params: { id: props.row._id },
                }"
              >
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteBlog(props.row._id)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

import moment from "moment";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BLink,
  BButton,
  BCol,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BLink,
    BButton,
    ToastificationContent,
    vSelect,
    BCol,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      languageSearch: null,
      isLoading: false,
      columns: [
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Language",
          field: "language",
        },
        {
          label: "Created at ",
          field: "createdAt",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      blogLanguage: [
        { label: "English", value: "en" },
        { label: "Arabic", value: "ar" },
      ],
    };
  },

  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },

    ...mapGetters({
      user: "auth/user",
    }),
  },
  created() {
    // this.$http.get("/good-table/basic").then((res) => {
    //   this.rows = res.data;
    // });
    // axios
    //   .get("posts/allBlogs/")
    //   .then((response) => (this.rows = response.data.data));
    this.getBlogs();
  },
  watch: {
    languageSearch: function () {
      this.getBlogs();
    },
  },

  methods: {
    formatDate(value) {
      return moment(String(value)).format("YYYY-MM-DD");
    },
    deleteBlog: async function (idBlog) {
      if (confirm("Are you sure you want to delete this Blog ?")) {
        axios.delete(`posts/${idBlog}`).then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: response.data.message,
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.rows = this.rows.filter((data) => data._id != idBlog);

          // this.$vs.loading.close();

          // this.$router.push({ name: "blogs" });
        });
        // .then((response) => (this.rows = response.data.data));
      }
    },

    getBlogs() {
      this.isLoading = true;
      const formData = new FormData();
      // formData.append("q", searchQuery.value);
      // formData.append("page", currentPage.value);
      // formData.append("perPage", perPage.value);
      if (this.languageSearch) {
        formData.append("language", this.languageSearch.value);
      }
      formData.append("type", "blog");

      axios.post("posts/search/", formData).then((response) => {
        this.rows = response.data.data;
        this.isLoading = false;
      });
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/vue/libs/vue-good-table.scss";
</style>
<style scoped></style>
